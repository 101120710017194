<template>
  <div>
    <search-roles @submit="submitSearch" />

    <a-table
      size="middle"
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
    >
      <span slot="effective" slot-scope="effective, record">
        <a-space>
          <a-switch
            :checked="effective"
            :loading="editingEffectiveId === record.id"
            :disabled="!isCanUpdateEffective(record.id, record.slug)"
            @change="(checked) => changeEffective(checked, record)"
          />
        </a-space>
      </span>
    </a-table>
    <!-- 分页 -->
    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />
  </div>
</template>

<script>
import SearchRoles from '@/views/roles/Search'
import { findManagerRoles, updateRoleEffective } from '@/api/role'
import Pagination from '@/components/Pagination'
import { hasPermission } from '@/utils/permission'
export default {
  components: {
    SearchRoles,
    Pagination
  },
  data() {
    return {
      columns: [
        {
          title: '角色名称',
          dataIndex: 'name',
          width: 200
        },
        {
          title: '有效性',
          dataIndex: 'effective',
          scopedSlots: { customRender: 'effective' },
          width: 200
        },
        {
          title: '关联账号数',
          dataIndex: 'user_count',
          width: 200
        }
      ],
      data: [],
      loading: false,
      query: {
        effective: '1'
      },
      editingEffectiveId: 0,
      pagination: {
        total_count: 0
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    submitSearch(search) {
      this.query = Object.assign({},
        this.$options.data().query,
        search)
      this.fetchData()
    },

    isHasEditPermission() {
      return hasPermission('roles/effective')
    },

    isCanUpdateEffective(recordId, recordRole) {
      if (!this.isHasEditPermission()) {
        return false
      }
      return true
    },

    changeEffective(checked, record) {
      const vm = this
      const effective = checked
      this.$confirm({
        title: effective ? '确定将角色生效吗?' : '确定失效角色吗?',
        content: '',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          vm.editingEffectiveId = record.id
          updateRoleEffective(record.id, { effective: effective })
            .then((res) => {
              vm.editingEffectiveId = 0
              if (res.code === 0) {
                record.effective = checked
              }
            })
            .catch(() => {
              vm.editingEffectiveId = 0
            })
        }
      })
    },

    fetchData() {
      this.loading = true
      findManagerRoles(Object.assign({}, this.query)).then((res) => {
        this.data = res.data
        this.pagination = res.pagination
        this.loading = false
      })
    }
  }
}

</script>
